document.addEventListener("DOMContentLoaded", () => {
  // Texte qui suit la souris
  const arrowSvg = document.querySelector(".arrow-container svg");

  if (arrowSvg) {
      const followText = document.createElement("span");
      followText.textContent = "Latest projects";
      followText.classList.add("follow-text");
      document.body.appendChild(followText);

      document.addEventListener("mousemove", (e) => {
          followText.style.left = `${e.clientX}px`;
          followText.style.top = `${e.clientY}px`;
      });

      arrowSvg.addEventListener("mouseenter", () => followText.style.opacity = 1);
      arrowSvg.addEventListener("mouseleave", () => followText.style.opacity = 0);
  }

  // Chargement de la page et barre de progression
  let percentage = 0;
  const percentageText = document.getElementById("percentage");
  const progressBarFill = document.getElementById("progress-fill");
  const loadingScreen = document.getElementById("loading-screen");

  function updateProgress() {
      percentage++;
      if (percentageText && progressBarFill) {
          percentageText.textContent = `${percentage}%`;
          progressBarFill.style.width = `${percentage}%`;
      }

      if (percentage < 100) {
          setTimeout(updateProgress, 5);
      } else {
          setTimeout(() => {
              if (loadingScreen) {
                  loadingScreen.style.opacity = "0";
                  loadingScreen.style.visibility = "hidden";
              }
              const mainContent = document.getElementById("main-content");
              if (mainContent) mainContent.style.display = "block";
          }, 400);
      }
  }

  updateProgress();

  // Défilement fluide avec la molette de la souris
  let scrollTimeout;
  window.addEventListener("wheel", () => {
      if (scrollTimeout) clearTimeout(scrollTimeout);
      document.documentElement.style.scrollBehavior = "auto";
      scrollTimeout = setTimeout(() => {
          document.documentElement.style.scrollBehavior = "smooth";
      }, 100);
  });

  // Gestion des vidéos cliquables
  document.querySelectorAll("video.grid-image").forEach((video) => {
      video.addEventListener("click", () => {
          video.paused ? video.play() : video.pause();
      });
  });

  // Mise à jour de l'heure dans le footer
  function updateTime() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");
      const timeElement = document.getElementById("time");
      if (timeElement) timeElement.textContent = `${hours}:${minutes}:${seconds}`;
  }

  setInterval(updateTime, 1000);
  updateTime();

  // Gestion du bouton de lecture personnalisé
  const video = document.getElementById("myVideo");
  const playButton = document.getElementById("playButton");

  if (video && playButton) {
      playButton.addEventListener("click", () => {
          video.paused ? video.play() : video.pause();
      });

      video.addEventListener("play", () => playButton.classList.add("hidden"));
      video.addEventListener("pause", () => playButton.classList.remove("hidden"));
      video.addEventListener("ended", () => playButton.classList.remove("hidden"));
  }

  // Chargement dynamique de contenu
  const loadContentBtn = document.getElementById("loadContentBtn");
  if (loadContentBtn) {
      loadContentBtn.addEventListener("click", () => {
          const contentDiv = document.getElementById("dynamicContent");
          if (contentDiv) {
              const newContent = document.createElement("p");
              newContent.textContent = "Voici du contenu chargé dynamiquement !";
              contentDiv.appendChild(newContent);
          }
      });
  }

  // Lazy loading des vidéos
  const lazyVideos = document.querySelectorAll("video");
  if (lazyVideos.length > 0) {
      const videoObserver = new IntersectionObserver(
          (entries, observer) => {
              entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                      const video = entry.target;
                      const source = video.querySelector("source");
                      if (source && source.dataset.src) {
                          source.src = source.dataset.src;
                          video.load();
                          video.play();
                          observer.unobserve(video);
                      }
                  }
              });
          },
          { threshold: 0.25 }
      );

      lazyVideos.forEach((video) => videoObserver.observe(video));
  }

  // Lazy loading des images et vidéos
  document.querySelectorAll("img, video").forEach((media) => {
      const src = media.getAttribute("src");
      if (src) {
          media.setAttribute("data-src", src);
          media.removeAttribute("src");
      }
  });

  setTimeout(() => {
      document.querySelectorAll("img, video").forEach((media) => {
          const dataSrc = media.getAttribute("data-src");
          if (dataSrc) {
              media.setAttribute("src", dataSrc);
              media.removeAttribute("data-src");
          }
      });
  }, 1000);
});
